import { createApp } from "vue";
// 引入组件
import App from "./App";
import ElementUI from 'element-plus';
import 'element-plus/dist/index.css'
// 引入资源
import router from "./router";
import MetaInfo from 'vue-meta-info';
import './assets/font/font.css'
import {Base64} from "js-base64"
import {ElMessage } from 'element-plus'
// 创建app
const app = createApp(App);

app.use(ElementUI);
app.use(MetaInfo)
app.use(Base64)
app.use(ElMessage)
/* 引入全局CSS */
import "@/assets/css/reset.css"; // 重置样式
import "@/assets/css/global.css"; // 全局样式
import "@/assets/css/compatible.css"; // 响应式兼容

// 引入util
import tool from "@/util/tool.js";
app.config.globalProperties.$tool = tool;

/* 全局请求axios */
import api from "@/axios/api"; //这里引入的是js文件
app.config.globalProperties.$api = api;

/* 全局请求axios */
import buss from "@/axios/buss"; //这里引入的是js文件
app.config.globalProperties.$buss = buss;

/* 全局工具 */
import checkStr from "@/util/checkStr.js"; //这里引入的是js文件

app.config.globalProperties.$checkStr = checkStr;
// 注入路由
app.use(router);

// 加入百度统计
router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    /*if (to.meta.title) {
        document.title = to.meta.title;
    }*/
    /*if (to.path) {
        if (window._hmt) {
            window._hmt.push(["_trackPageview", "" + to.fullPath]);
        }
    }*/
    /*if (to.matched[0].meta.description) {
        document.getElementsByName("description")[0].content =
            to.matched[0].meta.description;
    }
    if (to.matched[0].meta.keywords) {
        document.getElementsByName("keywords")[0].content =
            to.matched[0].meta.keywords;
    }*/
    next();
});
/*app.directive("web-description", {
    update: function(el, binding, vnode, oldVnode) {
        const { value } = binding;
        if (el.dataset.description) {
            document.getElementsByName("description")[0].content =
                el.dataset.description;
        } else if (value && value.description) {
            document.getElementsByName("description")[0].content = value.description;
        }
    }
});
app.directive("web-keywords", {
    update: function(el, binding, vnode, oldVnode) {
        const { value } = binding;
        if (el.dataset.keywords) {
            document.getElementsByName("keywords")[0].content = el.dataset.keywords;
        } else if (value && value.keywords) {
            document.getElementsByName("keywords")[0].content = el.dataset.keywords;
        }
    }
});*/

// 挂载实例
app.mount("#app");
