import axios from "axios";
import router from '../router'
import {ElMessage} from 'element-plus'

/****** 创建axios实例 ******/
const service = axios.create({
    timeout: 5000000 // 请求超时时间
});
/*
  String content = System.currentTimeMillis() + "";

        builder.addHeader("s", aesEncrypt(content + "", "5W@v6GMf%b@6aXPR5xfyYwrX5DGDSBtS", "F4WEf@N!zwk4Pokf"));
* */
service.interceptors.request.use(config => {
        // 修改请求头信息
        if (localStorage.getItem('token')) {
            config.headers['Authorization'] = JSON.parse(localStorage.getItem('token'))
        }
    // 要加密的文本
    var text = new Date().getTime()+'';
    console.log(text,'=====text==========')
// 自定义密钥和IV（请确保它们是安全的，并且是随机生成的）
    var key = CryptoJS.enc.Utf8.parse("5W@v6GMf%b@6aXPR5xfyYwrX5DGDSBtS");
    var iv = CryptoJS.enc.Utf8.parse("F4WEf@N!zwk4Pokf");

// 加密
    var encrypted = CryptoJS.AES.encrypt(text, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

// 转换为字符串（Base64编码）
    var encryptedString = encrypted.toString();

    console.log("加密后的内容:", encryptedString);
    config.headers['s'] = encryptedString
        return config
    })
    //axios请求拦截
service.interceptors.response.use(
    res => { //成功请求到数据
        if (res.data.code == 200) {
            return res.data
        } else {
            console.log(res.data.tips,'=============')
           // ElMessage.error(res.data.tips)
            return res.data;
        }
    },
    error => { //响应错误处理
        console.log('error');
        console.log(error);
        console.log(JSON.stringify(error));
        return Promise.reject(error)
    }
);

//请求方法封装，拷贝直接用~
export default {
    GET(url, data = {}) {
        return service({
            url: url,
            method: 'get',
            params: data,
            data: ""
        })
    },

    POST(url, data = {}) {
        return service({
            url: url,
            method: 'post',
            data: data,
            headers: {
                'Content-Type': 'application/json'
            },
        })
    },

    PUT(url, data = {}) {
        return service({
            url: url,
            method: 'put',
            data: data,
            headers: {
                'Content-Type': 'application/json'
            },
        })
    },

    DELETE(url, data = {}) {
        return service({
            url: url,
            method: 'delete',
            data: data,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        })
    }
}